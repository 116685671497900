<template>
  <div class="app-container v">
    <div class="head-container">
      <quick-select v-model="query.shopId" url="api/shop" placeholder="门店" clearable filterable @change="toQuery" value-field="id" display-field="shopName" class="filter-item" style="width: 200px;" />
      <quick-select class="filter-item" v-model="query.warehouseId" clearable displayField="name" url="api/warehouse" placeholder="发货库房" filterable @change="toQuery" style="width:180px" />
      <el-select v-model="query.statusList" class="filter-item" clearable placeholder="状态" style="width: 140px" multiple collapse-tags @change="toQuery">
        <template v-for="(o, i) in status">
          <el-option :key="o.label" :label="o.label" :value="i" />
        </template>
      </el-select>
      <!-- <el-select v-model="dateType" placeholder="日期类型" class="filter-item" style="width: 100px" @change="toQuery">
        <el-option v-for="(v,k) in dateTypes" :key="k" :label="v" :value="k" />
      </el-select> -->

      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="发货日期" end-placeholder value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" style="width: 250px;" />

      <el-input v-model="query.code" class="filter-item" :maxlength="20" placeholder="发货单号/零售单号/合同号" @keypress.enter.native="toQuery" style="width: 230px;" />
      <el-input v-model="query.customerName" class="filter-item" :maxlength="20" placeholder="收货人" @keypress.enter.native="toQuery" style="width: 120px;" />
      <!-- <el-input v-model="query.info" class="filter-item" placeholder="发货备注" @keypress.enter.native="toQuery" style="width: 120px;" /> -->
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload" v-permission="['RETAIL_DELIVERY_ALL','RETAILMANAGE_EXPORT']">导出</el-button>

      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <el-table border v-loading="loading" row-key="id" :data="data" :row-class-name="e => {return e.row.warehouseId ? '' : 'el-table-warning-row';}" height="200px">      
      <el-table-column prop="erpCode" label="商品编码" width="120"/>
      <el-table-column prop="goodsName" label="商品名称" min-width="140" show-overflow-tooltip/>
      <el-table-column prop="specs" label="规格" min-width="160" :formatter="$goodsSpecConvert" show-overflow-tooltip/>
      <el-table-column prop="quantity" label="发货数量" width="70" />
      <el-table-column prop="netAmount" label="发货金额" width="100" :formatter="$price"/>
      <el-table-column label="发货日期" width="85" :formatter="r => { return r.deliveryDate?new Date(r.deliveryDate).format('yyyy/MM/dd'):''}" />
      <el-table-column prop="formCode" label="发货单号" width="120" />
      <el-table-column label="零售单号" prop="dependFormCode" width="120" />
      <el-table-column label="门店" prop="shopName" width="120" show-overflow-tooltip/>            
      <el-table-column label="合同号" prop="contractNo" width="100" show-overflow-tooltip/>
      <el-table-column prop="customerName" label="收货人" width="100" />
      <el-table-column prop="customerPhone" label="联系电话" width="100" />
      <el-table-column label="收货地址" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.districtName}}{{scope.row.customerAddress}}</template>
      </el-table-column>
      <el-table-column label="发货仓库" width="110" prop="warehouseName" show-overflow-tooltip/>
      <el-table-column label="状态" width="80" fixed="right">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>

      <el-table-column width="45" fixed="right" align="center">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="view(scope.row)">查看</el-button>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <eForm ref="form1" />
  </div>
</template>

<script>
import eForm from "./form";
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      dateType: "sale",
      dateTypes: {
        sale: "发货日期",
        create: "创建日期",
      },
      downloadLoading: false,
      closing: false,
      delLoading: false,
      selection: null,
      status: {
        input: {
          type: "info",
          label: "编辑中",
        },
        submit: {
          type: "warning",
          label: "待审批",
        },
        confirm: {
          type: "success",
          label: "已确认",
        },
        reject: {
          type: "danger",
          label: "已拒绝",
        },
        close: {
          type: "info",
          label: "已关闭",
        },
        outbound: {
          type: "success",
          label: "已出库",
        },
        partOut: {
          type: "success",
          label: "部分已出库",
        },
      },
      order: {
        show: false,
        loading: false,
        current: null,
      },
      query: {
        status: null,
        dateRange: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/shop/deliveryNoticeItem";
      let query = JSON.parse(JSON.stringify(this.query));
      if (
        query.dateRange &&
        query.dateRange.length === 2 &&
        this.dateType === "create"
      ) {
        // 创建日期
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      if (
        query.dateRange &&
        query.dateRange.length === 2 &&
        this.dateType === "sale"
      ) {
        // 发货日期
        query.deliveryBegTime = query.dateRange[0];
        query.deliveryEndTime = query.dateRange[1];
      }

      delete query.dateRange;
      this.params = Object.assign(
        { status: query.status, sort: "createAt,desc" },
        query
      );
      return true;
    },
    handleSelectionChange(sels) {
      this.selection = sels;
    },
    checkReportable(data) {
      return data.status === "input";
    },

    view(data) {
      this.$refs.form1 && this.$refs.form1.resetForm(data);
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/shop/deliveryNotice/download", this.params)
        .then((result) => {
          downloadFile(result, "发货通知明细数据", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>